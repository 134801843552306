import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageContainer from '../PageContainer/PageContainer';
import { IAuthToken } from '../../types/types';
import MainHeader from '../MainHeader/MainHeader';
import { Toaster } from '../Toast';
import AllDealsContainerPage from '../AllDealsContainerPage/AllDealsContainerPage';

interface AppRouterProps {
  user: IAuthToken;
  logout: () => void;
  isSuperUser: boolean;
}
const AppRouter: React.FC<AppRouterProps> = ({ user, logout, isSuperUser }) => {
  return (
    <div data-testid='app-router'>
      <Toaster />
      <MainHeader
        emailAddress={user?.email}
        shortname={user?.preferred_username.toUpperCase()}
        logout={logout}
        isSuperUser={isSuperUser}
      />
      <Routes>
        <Route path='/' element={<PageContainer user={user} />} />
        <Route path='/kpi-calculator' element={<PageContainer user={user} />} />
        {isSuperUser && <Route path='/all-deals' element={<AllDealsContainerPage user={user} />} />}
      </Routes>
    </div>
  );
};

export default AppRouter;
