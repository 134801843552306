import Decimal from 'decimal.js';
import { type ICalculatorFormState } from '../../types/types';

export const calculateFormInitialState: ICalculatorFormState = {
  isGoToStepTwoClicked: false,
  isFormValidated: false,
  stepTwoTableFirstCol: '',
  technologySource: [],
  selectedTechnologySources: [],
  startYear: 0,
  endYear: 0,
  startDate: '',
  endDate: '',
  commodity: '',
  commodityId: 0,
  countryId: 0,
  gridLocation: '',
  gridLocationId: 0,
  grossMargin: new Decimal(0.0),
  region: '',
  opportunity: '',
  uomVal: '',
  business: '',
  businessId: 0,
  operatingModel: '',
  efficiency: '',
  equityShare: '',
  fuelType: '',
  assetType: '',
  activeKey: [0],
  carbonIntensityForGrid: [],
  carbonIntensityForNonGrid: [],
  userTableInputData: {},
  isShowKpiData: false,
  gasFiredPowerTableData: [],
  carbonIntensityForGas: [],
  stepTwoTableData: [],
  isGetOutputKpi: false,
  isGetOutputKpiServiceFailure: false,
  isHideAllButtons: false,
  carbonIntegrationData: [],
  isFetchCIdataServiceFailure: false,
  operatedScope: 0,
  equityScope: 0,
  operatedScopeYearWiseObj: { techSource: '', 2023: '' },
  equityScopeYearWiseObj: { techSource: '', 2023: '' },
  carbonIntensityYearWise: [],
  carbonIntensityUomYearWise: [],
  carbonEmissionYearWise: [],
  timeStampDataYearWise: [],
  carbonIntensityForGasFiredPowerYearWise: {},
  timeStampDataForGasFiredPowerYearWise: {},
  scope1CmbCrbnInt: 0,
  scope1CmbCrbnIntYearWiseObj: { techSource: '', 2023: '' },
  powerPerformanceStandardKpiMessage: '',
  mcrAverage: 0,
  isSuperUser: false,
};
