export const NET_ABS_EMISSION_SCOPE_PEAK_ANNUAL_EMISSION =
  'Net Absolute Emissions Scope 1,2 & 3 (Peak annual emissions)';
export const NET_ABS_EMISSION_SCOPE_TOTAL_EMISSION =
  'Net Absolute Emissions Scope 1,2 & 3 (Total emissions)';
export const MARGIN_TO_CARBON_RATIO_AVG = 'Margin to Carbon Ratio (Average)';
export const DEAL_TERM = 'Deal Term';
export const TOTAL_CO2_EMISSION = 'Total CO2 emissions';
export const AVG_CARBON_INT = 'Avg Carbon Intensity**';
export const MARGIN_TO_CO2_RATIO = 'Margin to CO2 ratio';
export const EQUITY_SCOPE_CARBON_PEAK_ANNUAL_EMISSION =
  'Equity Scope 1&2 Carbon (Peak annual emissions)';
export const OPERATED_SCOPE = 'Operated Scope 1&2 (MTPA)';
export const EQUITY_SCOPE = 'Equity Scope 1&2 (MTPA)';
export const NET_ABSOLUTE_EMISSIONS = 'Net Absolute Emissions (MTPA)';
export const ASSET_SCOPE1_CI = 'Scope 1 Combustion Carbon Intensity';
export const TECH_SOURCE_COLUMN = 'Tech Source, ';
export const GVCET = 'Gas value chain emissions-Thermal';
export const FUEL_CO2E = 'Fuel CO2e';
export const LNG_NCF_CI = 'LNG NCF intensity';
export const GAS_END_USE_CF_CI = 'Gas end-use intensity';
export const MMBTU = 'MMBTU';
export const MWH = 'MWh';
export const TECHSOURCE_TWH = 'Tech Source TWh';
export const PJ = 'PJ';
export const NATURAL_GAS = 'Natural Gas';
export const POWER = 'Power';
export const PIPELINE_GAS = 'Pipeline Gas';
export const AMERICAS = 'Americas';
export const ASIA = 'Asia';
export const AUSTRALIA = 'Australia';
export const WIND = 'Wind';
export const SOLAR = 'Solar';
export const HYDROPOWER = 'Hydropower';
export const BIOMASS = 'Biomass';
export const GEOTHERMAL = 'Geothermal';
export const OIL = 'Oil';
export const COAL = 'Coal';
export const GRID = 'Grid';
export const GAS_FIRED_GENERATION = 'Gas Fired Generation';
export const FEEDBACK_LINK = 'https://forms.office.com/e/PWgg8Ln0WV';
export const HELP_LINK =
  'https://eu001-sp.shell.com/sites/AAAAA0900/Documents/Forms/AllItems.aspx?id=%2fsites%2fAAAAA0900%2fDocuments%2fSTSE_PRJ2154223_Carbon%20Management%20Framework%2fDeal%20Tool%20FAQs_v02%20July%202023.pdf&parent=%2fsites%2fAAAAA0900%2fDocuments%2fSTSE_PRJ2154223_Carbon%20Management%20Framework';
export const FEEDBACK_LABEL = 'Feedback';
export const HELP_LABEL = 'Help';
export const DOWNLOAD_DEALS = 'Download Deals';
export const DELETE_DEALS = 'Delete Deals';
export const UPDATE_DEALS = 'Update Deals in Crabon Dashboard';
export const SERVICE_FAILURE_CONSOLE_LOG = 'Get output KPI service failure =>';
export const API_VERSION_ONE = 'api/v1/';
export const BROWNFIELD = 'BROWNFIELD';
export const GREENFIELD = 'GREENFIELD';
export const MESSAGES = {
  peakAnnualEmmision: {
    below50KtaMessage:
      'There are <strong>no specific Carbon requirements</strong> (Equity Scope 1&2 <0.050 mtCO2e; Carbon Significant threshold). Proceed without Carbon Functional Approval',
    between50To200KTAmessage:
      'This opportunity is <strong>Carbon Significant</strong>  (Equity Scope 1&2 (Peak Annual Emissions) > 0.050 mtCO2e and < 0.200 mtCO2e)',
    above200KTAmessage:
      'This opportunity is <strong>Carbon Critical</strong> (Equity Scope 1&2 (Peak Annual Emissions) > 0.200 mtCO2e)',
  },
  performanceStandard: {
    carbonCritical: {
      contactInfo:
        'Please contact the following for Carbon Functional Support: Moene, Robert GSNL-PTS/ES robert.moene@shell.com',
      assetBrownfield: {
        efficiencyAbove57Info:
          'Opportunity <strong>Does Meet</strong> Performance Standard (Brownfield >=58% LHV Efficiency)',
        efficiencyBelow58Info:
          'Opportunity <strong>Does Not Meet</strong> Performance Standard (Brownfield <58% LHV efficiency)',
      },
      assetGreenfield: {
        efficiencyAbove59Info:
          'Opportunity <strong>Does Meet</strong> Performance Standard (Greenfield >=60% LHV Efficiency)',
        efficiencyBelow60Info:
          'Opportunity <strong>Does Not Meet</strong> Performance Standard (Greenfield <60% LHV efficiency)',
      },
    },
    carbonSignificant: {
      contactInfo:
        'Please contact the following for Carbon Functional Support: Cray, David GSCA-PTS/ED David.Cray@shell.com',
      assetBrownfield: {
        efficiencyAbove39Info:
          'Opportunity <strong>Does Meet</strong> Performance Standard (Brownfield >=40% LHV Efficiency)',
        efficiencyBelow40Info:
          'Opportunity <strong>Does Not Meet</strong> Performance Standard (Brownfield <40% LHV efficiency)',
      },
      assetGreenfield: {
        efficiencyAbove41Info:
          'Opportunity <strong>Does Meet</strong> Performance Standard (Greenfield >=42% LHV Efficiency)',
        efficiencyBelow42Info:
          'Opportunity <strong>Does Not Meet</strong> Performance Standard (Greenfield  <42% LHV efficiency)',
      },
    },
  },
  operatingModel: {
    sovOrNov: 'Performance Standard is <strong>Mandatory</strong> (equity investment)',
    lease: 'Performance Standard is <strong>Best Practice</strong> (lease)',
  },
};
export const POWER_PERFORMANCE_STANDARD = 'Power Performance Standard';
export const POWER_PERFORMANCE_STANDARD_DESCRIPTION =
  'Power Performance Standard evaluation is a control framework requirement for Equity Investments and a best practice for Leases.';
export const LAST_UPDATE_TIMESTAMP = 'LAST_UPDATE_TIMESTAMP';
export const FOOT_NOTE =
  'For Pipeline gas commodity selection, Value Chain Carbon Intensity unit of measure is gCO2e/MJ and for Power commodity it is tCO2e/MWh.';
export const KPI_SUMMARY = 'KPI Summary';
export const UNIT_FOR_MEASURE_CONVERTER = 'UOM and FX rates converter';
export const UNIT_FOR_MEASURE_CONVERTER_LINK =
  'https://apps.powerapps.com/play/e/af5c03df-f395-405c-a93f-87a7408adb36/a/2fecfd69-772f-411e-9f07-9150929a8013?tenantId=db1e96a8-a3da-442a-930b-235cac24cd5c&hint=6f70da29-d4b2-4be0-9c80-1be6671e1805&sourcetime=1718012337324';
export const contactPerson = [
  { email: 'David.Cray@shell.com', name: 'David Cray' },
  { email: 'robert.moene@shell.com', name: 'Robert Moene' },
];
export const CARBON_SIGNIFICANT = 'carbonSignificant';
export const CARBON_CRITICAL = 'carbonCritical';
export const ASSET_BROWNFIELD = 'assetBrownfield';
export const ASSET_GREENFIELD = 'assetGreenfield';
export const MTCO2E = 'mtCO2e';
export const MCR_AVG_UOM = '$/tCO2e';
export const FETCH_ERROR = 'Error fetching data';
export const DEAL_MOVED = 'Deal moved to carbon dashboard';
export const DEAL_REMOVED = 'Deal removed from carbon dashboard';
export const DEAL_DELETED = 'Deals deleted successfully';
export const SELECT_DEALS = 'Please select atleast one deal to delete.';
