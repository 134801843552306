/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { AuthService } from './AuthService';
import jwt_decode from 'jwt-decode';
import {
  Button,
  Flexbox,
  Grid,
  Heading,
  Pecten,
  Sentiments,
} from '@sede-x/shell-ds-react-framework';
import {
  LoginContainer,
  BackgroundVideo,
  Login,
  LoginGrid,
  GridContainer,
} from './AppContent.styles';
import { notify } from '../components/Toast/notify';
import { FETCH_ERROR } from '../api/constants';
import { IAuthToken } from '../types/types';
import appInsights from '../appInsights';
import AppRouter from '../components/AppRouter';

const AppContent: React.FC = () => {
  const authService = new AuthService();
  const [user, setUser] = useState<IAuthToken | null>(null);
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
  useEffect(() => {
    getUser();
  }, []);

  const login = () => authService.login();
  const logout = async () => {
    appInsights.trackEvent({
      name: 'User logged out',
      properties: {
        email: user?.email,
        username: user?.preferred_username,
        role: user?.aud,
      },
    });
    localStorage.removeItem('isAlreadyLoggedInStorage');
    await authService.logout();
  };

  const getUser = () => {
    authService
      .getUser()
      .then((userDetails) => {
        if (userDetails) {
          const userData = jwt_decode(userDetails.access_token);
          setUser(userData as IAuthToken);
          const isSuUser = (userData as IAuthToken).realm_access.roles.includes('SECPM_SU');
          setIsSuperUser(isSuUser);
        }
      })
      .catch(() => {
        notify('error', FETCH_ERROR);
      });
  };

  // below code is to handle multiple /kpi-calculator instances in url. Will handle it after go live.
  const url = window.location.href;
  const occurrences = url.split('/kpi-calculator').length - 1;
  if (occurrences > 1) {
    window.location.href = url.replace(/\/kpi-calculator\/kpi-calculator/, '/kpi-calculator');
  }

  // added  pulsating pecten as media file in the code as mp4

  return user === null || Object.keys(user).length === 0 ? (
    <LoginContainer>
      <BackgroundVideo autoPlay loop muted>
        <source src='/pulsating-pecten.mp4' type='video/mp4' />
      </BackgroundVideo>
      <LoginGrid justifyContent='center' alignItems='center'>
        <Login>
          <Grid gap='24px' areas={['blank1', 'login', 'blank2']}>
            <Grid.Cell area='blank1' />
            <Grid.Cell area='login'>
              <Flexbox justifyContent='center'>
                <GridContainer>
                  <Grid
                    gap='24px'
                    areas={['spinner', 'pecten', 'signonText', 'authText', 'button']}
                  >
                    <Grid.Cell area='pecten'>
                      <Flexbox justifyContent='center'>
                        <Pecten size='medium' />
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area='signonText'>
                      <Flexbox justifyContent='center'>
                        <Heading type='h1'>
                          Single sign-on to <strong>Carbon KPI Calculator</strong>
                        </Heading>
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area='authText'>
                      <Flexbox justifyContent='center'>
                        <p>
                          Authenticate your account by logging into Shell's single sign-on provider.
                        </p>
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area='button'>
                      <Flexbox justifyContent='center'>
                        <Button sentiment={Sentiments.Positive} onClick={login} size='medium'>
                          Continue
                        </Button>
                      </Flexbox>
                    </Grid.Cell>
                  </Grid>
                </GridContainer>
              </Flexbox>
            </Grid.Cell>
            <Grid.Cell area='blank2' />
          </Grid>
        </Login>
      </LoginGrid>
    </LoginContainer>
  ) : (
    <AppRouter user={user} logout={logout} isSuperUser={isSuperUser} />
  );
};

export default AppContent;
