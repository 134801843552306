import { GlobalHeader } from '@sede-x/glass-design-library';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@sede-x/shell-ds-react-framework';
import { LOGOUT, SUB_TITLE_KPI_CALCULATOR, TABS_MENU, TITLE_KPI_CALCULATOR } from '../../constants';
import { FEEDBACK_LABEL, FEEDBACK_LINK, HELP_LABEL, HELP_LINK } from '../../api/constants';
import CustomButton from '../CustomButton/CustomButton';

interface MainHeaderProps {
  shortname: string;
  emailAddress: string;
  logout: () => void;
  isSuperUser: boolean;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  shortname,
  emailAddress,
  logout,
  isSuperUser,
}) => {
  const navigate = useNavigate();
  // set default to kpi-calculator tab
  const tab = window?.location?.pathname?.substring(1) || 'kpi-calculator';
  // Function to handle tab change
  const handleTabChange = (currentTab: string) => {
    navigate('/' + currentTab);
  };

  const tabsMenu = isSuperUser
    ? TABS_MENU
    : TABS_MENU.filter((tabMenu) => tabMenu.key !== 'all-deals'); // Removing All deals tab if user doesn't have access
  return (
    <div style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
      <GlobalHeader
        hideGlassHub
        avatar={{
          description: emailAddress,
          title: shortname,
          dropdownOverlay: (
            <Menu>
              <MenuItem key='logout' onClick={logout}>
                {LOGOUT}
              </MenuItem>
            </Menu>
          ),
        }}
        subtitle={SUB_TITLE_KPI_CALCULATOR}
        title={TITLE_KPI_CALCULATOR}
        defaultTab={tab}
        onChangeTab={(currentTab) => {
          handleTabChange(currentTab);
        }}
        tabs={tabsMenu}
        rightComponent={
          <span className='display-flex'>
            <CustomButton label={HELP_LABEL} link={HELP_LINK} />
            <CustomButton label={FEEDBACK_LABEL} link={FEEDBACK_LINK} />
          </span>
        }
      />
    </div>
  );
};

export default MainHeader;
