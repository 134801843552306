import { BaseTable, Card, Sizes, Spacings } from '@sede-x/shell-ds-react-framework';
import { KPI_SUMMARY } from '../../../../../api/constants';
import { three } from '../../../../../constants';
import { TKpiSummaryCol } from '../../../../../types/types';

const SummaryTable = (props: {
  maxEquityScope: TKpiSummaryCol;
  maxNetAbsoluteEmission: TKpiSummaryCol;
  maxMCR: TKpiSummaryCol;
}) => {
  const { maxEquityScope, maxNetAbsoluteEmission, maxMCR } = props;
  const cardTableData = [
    {
      KPIs: 'Equity Scope 1&2 Carbon (Peak annual emissions)',
      Value: (maxEquityScope?.value ?? 0).toFixed(three),
      Unit: maxEquityScope?.unit,
    },
    {
      KPIs: 'Net Absolute Emissions Scope 1,2 & 3 (Peak annual emissions)',
      Value: (maxNetAbsoluteEmission?.value ?? 0).toFixed(three),
      Unit: maxNetAbsoluteEmission?.unit,
    },
    {
      KPIs: 'Margin to Carbon Ratio (Average)',
      Value: (maxMCR?.value ?? 0).toFixed(three),
      Unit: maxMCR?.unit,
    },
  ];
  const cols = [
    {
      accessorKey: 'KPIs',
      header: 'KPIs',
    },
    {
      accessorKey: 'Value',
      header: 'Value',
    },
    {
      accessorKey: 'Unit',
      header: 'Unit',
    },
  ];

  return (
    <Card
      size={Sizes.Small}
      bodyPadding={true}
      bodyBgColor='base'
      header={{
        title: KPI_SUMMARY,
      }}
      className='kpiCardMaxWidth'
      padding={Spacings.Tight}
    >
      <BaseTable
        style={{ width: '100%' }}
        size='small'
        noFooter
        maxHeight={1000}
        columns={cols}
        data={cardTableData}
      />
    </Card>
  );
};

export default SummaryTable;
